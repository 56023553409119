import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  // background: ${(props) => props.theme.palette.background.bg1};
  background: #212121;
  flex-direction: column;
  box-sizing: border-box;
`;

export const FooterPaymentIconsWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider.d1};
  border-bottom: 1px solid #343434;
  padding: 12px 18px;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 8px 12px;
  }
`;

export const FooterHeaderTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1232px;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    align-items: flex-start;

    gap: 8px;
  }
`;

export const FooterPaymentIcons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.palette.text.t3};
  font-family: Onest-Medium;
  font-size: 14px;
  line-height: 14px;

  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 12px;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin: 40px auto;
  margin-bottom: 32px;
  display: grid;
  max-width: 1232px;
  grid-template-columns: 2.3fr 2.7fr 2.5fr 2.5fr 2.5fr;
  // grid-template-columns: 2.3fr 2.7fr 2.5fr 2.5fr;
  grid-gap: 20px;
  padding: 0 16px;
  /* display: flex;
  > div {
    width: 16.65%;
    &:nth-child(1) {
      width: 22%;
      padding-right: 1rem;
    }
    &:nth-child(3),
    &:nth-child(2) {
      width: 14%;
    }
    &:nth-child(4),
    &:nth-child(5) {
      width: 18%;
    }
  } */
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px;
    margin: 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 16px;
    margin: 0;
  }
`;

export const FooterMain = styled.div``;

export const FooterItemFull = styled.div`
  margin: 0;
  @media (max-width: 1024px) {
    grid-column-end: auto;
    margin-bottom: 30px;
  }
`;

export const FooterHeader = styled.div`
  font: 1rem/1.5rem Onest-Bold;
  color: #fff;
  margin-bottom: 1.5rem;
`;

export const FooterItem = styled.div`
  margin: 0;
  @media (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

export const FooterRatingContainer = styled.div`
  justify-self: end;
  // color: ${(props) => props.theme.palette.text.t2};
  color: #fff;
  & div {
    color: #fff !important;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    justify-self: start;
  }
`;

export const RatingContainer = styled.div`
  display: grid;
  justify-items: end;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    justify-items: start;
  }
`;
export const RatingImg = styled.img`
  width: 138px;
  height: 45px;
`;
export const RatingDetails = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  font-family: Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 12px;
`;
export const Rating = styled.div`
  padding-right: 10px;
  border-right: solid 2px #fff;
`;
export const TotalReviews = styled.div`
  padding-left: 10px;
`;

export const FooterItemTitle = styled.div`
  font-family: Onest-Medium;
  font-size: 1rem;
  line-height: 1.25rem;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #fff;

  // color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;
  a {
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export const BrandLogoContainer = styled.div`
  width: 10px;
`;

export const FooterItemContent = styled.div`
  margin-top: 1rem;
  font-size: 14px;
  color: ${COLORS.greyDark};
  /* color: ${COLORS.primary}; */
`;

export const FooterItemSub = styled.div`
  font-size: 14px;
  margin-top: 0.5rem;
  display: inline-block;
  color: ${COLORS.primary};
  &:hover {
    text-decoration: underline;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  a {
    &:nth-last-child(1) {
      div {
        margin-right: 0;
      }
    }
  }
`;

export const IconContainer = styled.div`
  margin-right: 14px;
  span {
    margin-right: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    & a {
      display: block;
      height: 25px;
      width: 25px;
    }
  }
`;

export const FooterItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  a {
    display: block;
    margin-bottom: 0.65rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    &:hover {
      color: ${COLORS.primary};
    }
  }
`;

export const FooterItemListItem = styled.li`
  color: #14012d;
  cursor: pointer;
  font-size: 0.9rem;
  display: block;
  margin-bottom: 0.65rem;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  &:hover {
    color: ${COLORS.primary};
  }
`;

export const FooterStripContainer = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px 16px 84px;
    width: 100%;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px 16px 32px;
  }
`;

export const FooterStrip = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  @media (max-width: ${WIDTH.mobileMax}) {
    align-items: start;
    flex-direction: column;
    gap: 24px;
  }
`;

export const FooterStripNavContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  a {
    font: 0.875rem/1.125rem Onest-Medium;
    color: #fff;
  }
  margin-right: 24px;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    align-items: start;
    gap: 16px;
    & a {
      width: 100%;
    }
  }
`;

export const FooterPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 36px;
  font-family: Onest-Regular;
  font-size: 1rem;
  line-height: 16px;
  font-family: Onest-Medium;
  line-height: 20px;
  align-items: center;
  &.privacy_footer {
    justify-content: flex-end;
  }
  .upi_logo {
    display: flex;
    img {
      height: 26px;
    }
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-wrap: wrap;
    row-gap: 16px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    align-items: start;
    flex-direction: column;
    row-gap: 24px;
  }
`;

export const TrustTag = styled.div`
  padding: 8px 12px;
  background: ${COLORS.greyLight4};
  color: ${COLORS.black};
  font-size: 14px;
  line-height: 16px;
  font-family: Onest-Medium;
  border-radius: 16px;
  width: fit-content;
`;

export const BrandLogoImage = styled.img`
  height: 28px;
  cursor: pointer;
  min-width: 80px;
  width: 122px;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 24px;
    min-width: 96px;
    max-width: 96px;
  }
`;

export const FooterLanguageContainer = styled.div`
  display: flex;
  // max-width: 1232px;
  gap: 16px;
  align-items: center;
  // padding: 0 16px;
  width: 100%;
  // margin: 0 auto 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 16px 0;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 16px 0;
    // padding: 16px;
    margin: 0;
  }
`;

export const FooterLanguageTitle = styled.div`
  font: 0.875rem/1.125rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t2};
  @media (max-width: ${WIDTH.tabletMax}) {
    font: 0.875rem/1.125rem Onest-Bold;
    color: #fff;
  }
`;

export const CompanyInfo = styled.p`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #343434;
  color: ${(props) => props.theme.palette.text.t2};
  font: 0.75rem/1rem Onest-Medium;
  & span {
    font-family: Onest-SemiBold;
  }
  text-align: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding-top: 20px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    // padding-bottom: 40px;
  }
`;

export const RecaptchaBadgeContainer = styled.div`
  text-align: center;
  font: 0.75rem/1rem Onest-Medium;
  padding-top: 4px;
  color: ${(props) => props.theme.palette.text.t3};
  a {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: underline;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 8px 16px 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 8px 16px 40px;
  }
`;

export const NewsletterStrip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1232px;
  margin: 0 auto 16px;
  padding: 0 16px;
  & > p:first-of-type {
    color: #fff;
  }
  @media (max-width: ${WIDTH.tabletMin}) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
`;
