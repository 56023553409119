/* eslint-disable @next/next/no-img-element */
import { PAYMENT_METHOD_NAMES } from '../../utils/paymentMethodNames';

export const exportFooterPaymentIcon = (
  x: string,
  height: string,
  width: string,
  margin: string,
  color: string
) => {
  switch (x) {
    case 'DEBIT_CARD':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/mastercard.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using mastercard"
        />
      );
    case 'CARTE_BANCAIRE':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/cartes-bancaires.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using carte bancaire"
        />
      );
    case 'AMEX':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/amex.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using amex"
        />
      );
    case 'CREDIT_CARD':
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          <img
            src="/icons/payments-icons/footer-payment-icons/visa.svg"
            height={height}
            width={width}
            style={{ height: height || 'auto' }}
            alt="pay using visa"
          />
          <img
            src="/icons/payments-icons/footer-payment-icons/mastercard.svg"
            height={height}
            width={width}
            style={{ height: height || 'auto' }}
            alt="pay using mastercard"
          />
        </div>
      );
    case 'UPI':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/upi.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using upi"
        />
      );
    case 'NET_BANKING':
      return (
        <img
          src="/icons/payments-icons/card-24.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using net banking"
        />
      );

    case 'WALLETS':
      return (
        <img
          src="/icons/payments-icons/card-24.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using wallets"
        />
      );
    case PAYMENT_METHOD_NAMES.OVO:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/ovo.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using ovo"
        />
      );
    case PAYMENT_METHOD_NAMES.QRIS:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/qris.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using qris"
        />
      );
    case PAYMENT_METHOD_NAMES.DANA:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/dana.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using dana"
        />
      );
    case PAYMENT_METHOD_NAMES.ZONDA_CRYPTO:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/crypto.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using zonda"
        />
      );

    case PAYMENT_METHOD_NAMES.PIX_QR:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/pix.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using pix"
        />
      );
    case PAYMENT_METHOD_NAMES.LINK_AJA:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/linkaja.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using linkaja"
        />
      );

    case 'IDEAL':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/ideal.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using ideal"
        />
      );
    case 'GIROPAY':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/giropay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using giropay"
        />
      );
    case 'P24':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/p24.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using p24"
        />
      );
    case PAYMENT_METHOD_NAMES.GRAB_PAY:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/grabpay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using grabpay"
        />
      );
    case PAYMENT_METHOD_NAMES.CHINA_UNION_PAY: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/unionpay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using unionpay"
        />
      );
    }
    case PAYMENT_METHOD_NAMES.PAYNOW_QR:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/paynow.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using paynow"
        />
      );
    case PAYMENT_METHOD_NAMES.PROMPT_PAY_QR:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/promptpay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using promptpay"
        />
      );
    case PAYMENT_METHOD_NAMES.ALIPAY:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/alipay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using alipay"
        />
      );
    case PAYMENT_METHOD_NAMES.POLI:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/poli.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using poli"
        />
      );
    case PAYMENT_METHOD_NAMES.PAYMAYA:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/maya.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using maya"
        />
      );
    case PAYMENT_METHOD_NAMES.SHOPEE_PAY:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/shopee-pay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using shopee pay"
        />
      );
    case PAYMENT_METHOD_NAMES.FPX:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/fpx.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using fpx"
        />
      );
    case PAYMENT_METHOD_NAMES.BLIK:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/blik.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using blik"
        />
      );

    case PAYMENT_METHOD_NAMES.INSTANT_BANK_TRANSFER:
      return (
        <img
          src="/icons/payments-icons/card-24.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using bank"
        />
      );

    case 'PAYPAL':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/paypal.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using paypal"
        />
      );
    case 'EPS':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/eps.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using eps"
        />
      );
    case 'BANCONTACT':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/bancontact.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using bancontact"
        />
      );
    case 'WECHAT_PAY':
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/wechatpay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using wechatpay"
        />
      );

    case PAYMENT_METHOD_NAMES.SKRILL_WALLET:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/skrill.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using skrill"
        />
      );

    case PAYMENT_METHOD_NAMES.PAYSAFE_CARD:
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/paysafe.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using paysafe"
        />
      );

    case 'PAYCONIQ': {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/paycoinq.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using payconiq"
        />
      );
    }

    case 'MBWAY': {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/mbway.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using mbway"
        />
      );
    }

    case 'MULTIBANCO': {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/multibanco.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using multibanco"
        />
      );
    }

    case 'AIRCASHPAY': {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/aircash.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using aircash"
        />
      );
    }

    case 'SATISPAY': {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/satispay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using satispay"
        />
      );
    }

    case 'MYBANK': {
      //TODO: replace the url to mybank
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/mybank.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using mybank"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.TRUSTLY: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/trustly.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using trustly"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.SOFORT: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/sofort.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using sofort"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.NETELLER: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/neteller.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using neteller"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.SAFETYPAY: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/safetypay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using safetypay"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.KAKAOPAY: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/kakaopay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
          alt="pay using kakao pay"
        />
      );
    }

    case PAYMENT_METHOD_NAMES.PSE: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/pse.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BOLETO: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/boleto.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.PAGO_EFECTIVO: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/pago_efectivo.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.RAPI_PAGO: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/rapipago.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.REDPAGOS: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/redpagos.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.OXXO: {
      return (
        <img
          src="/icons/payments-icons/footer-payment-icons/oxxo.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.ATM_TRANSFER_INDONESIA: {
      return (
        <img
          src="/icons/payments-icons/atm_transfer_indonesia.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BANK_TRANSFER_KOREA: {
      return (
        <img
          src="/icons/payments-icons/bank_transfer_korea.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BANK_TRANSFER_PERU: {
      return (
        <img
          src="/icons/payments-icons/bank_transfer_peru.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BANK_TRANSFER_THAILAND: {
      return (
        <img
          src="/icons/payments-icons/bank_transfer_thailand.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.CENPAY: {
      return (
        <img
          src="/icons/payments-icons/cenpay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.DOKU_WALLET: {
      return (
        <img
          src="/icons/payments-icons/doku_wallet.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BANK_TRANSFER_EUROPE: {
      return (
        <img
          src="/icons/payments-icons/europe_bank_transfer.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.JENIUS_PAY: {
      return (
        <img
          src="/icons/payments-icons/jenius_pay.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.KONBINI: {
      return (
        <img
          src="/icons/payments-icons/konbini.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.KPLUS: {
      return (
        <img
          src="/icons/payments-icons/kplus.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.KRUNGSRI_MOBILE_APPLICATION: {
      return (
        <img
          src="/icons/payments-icons/krungsri_mobile_application.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.BANK_TRANSFER_POLAND: {
      return (
        <img
          src="/icons/payments-icons/poland_bank_transfer.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.SCB_EASY: {
      return (
        <img
          src="/icons/payments-icons/scb_easy_application.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    case PAYMENT_METHOD_NAMES.SHOPEE_PAY_THAILAND: {
      return (
        <img
          src="/icons/payments-icons/shopeepay_thailand.svg"
          height={height}
          width={width}
          style={{ height: height || 'auto' }}
        />
      );
    }

    default:
      return null;
  }
};
